<template>
	<div class="text-center">
		<v-dialog
			v-model="dialog"
			persistent
			:width="modalWidth"
			:fullscreen="belowDesktop"
			content-class="topic-introduction-modal"
		>
			<v-card v-if="modalContent" class="pa-6">
				<div class="close-action" :class="[belowDesktop && 'pa-4']">
					<v-btn icon @click="close">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="card-title text-center">
					<h5 class="ortho-text-gray">Espace verrouillé</h5>
					<p>{{ unlockSetence(topic) }}</p>
				</div>

				<div class="card-body">
					<div class="left-col d-flex align-center w-100">
						<VideoResource :resourceItem="{ url: modalContent.videoLink }" />
					</div>

					<div class="right-col d-flex align-center">
						<div v-if="!belowDesktop" class="stroke"></div>
						<div>
							<p class="text-center">
								<b>{{ modalContent.title }}</b>
							</p>
							<div>
								<ul>
									<li v-for="(bullet, index) in modalContent.bulletPoints" :key="index">
										{{ bullet }}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="text-center mt-5" :class="[belowDesktop && 'pa-4']">
					<v-btn
						depressed
						class="black white--text cta-btn"
						target="_blank"
						href="https://calendly.com/coaching-orthographiq/prochains-parcours-orthographiq?utm_campaign=ask-info-new-formation&utm_source=espace-perso-app"
						>Debloquer la formation
					</v-btn>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { getMonthInFrench } from '@/utils/time.js';
import { setZindexByClassNames } from '@/utils/helper.js';
import VideoResource from '../supportResources/VideoResource.vue';

const DataForOrthographiqTopic = {
	videoLink: 'https://www.youtube.com/watch?v=Zl_BSM5Dh2g',
	title: 'Maîtrises enfin les règles d’orthographe facilement',
	bulletPoints: [
		'🔎 Une sélection des règles d’orthographe les plus importantes.',
		'✨ Sur-mesure : des exercices adaptés à ton niveau pour des progrès rapides et efficaces.',
		'🔥 Challenges : chaque exercice te fait gagner des points pour grimper dans le classement.',
		'💡 Tout au même endroit : retrouves facilement toutes les règles apprises pour réviser à volonté.',
	],
};

const DataForRedactionTopic = {
	videoLink: 'https://www.youtube.com/watch?v=D5Jh2ES6SFo&ab_channel=OrthographIQ',
	title: 'Améliore tes écrits professionnels',
	bulletPoints: [
		'🆕 De nouveaux formats de question comme l’expression libre, la réponse à un email ou encore la rédaction d’un rapport de réunion',
		'📝 Écris et soit corrigé instantanément grâce à notre intelligence artificielle',
		'✨ Des leçons interactives avec des fiches téléchargeables et des vidéos pour comprendre toutes nos astuces',
		'🧠 Des sessions complètes de 30 min pour écrire comme un pro',
	],
};

export default {
	name: 'TopicIntroductionModal',
	props: { topic: Object, closeModalInParent: Function },
	components: { VideoResource },
	data() {
		return { dialog: true, modalContent: null };
	},
	computed: {
		resourceItem() {
			return { url: 'https://www.youtube.com/watch?v=D5Jh2ES6SFo&ab_channel=OrthographIQ' };
		},
		modalWidth() {
			if (window.innerWidth > 1904) return '50%';
			if (window.innerWidth >= 960) return '70%';
			return '100%';
		},
		belowDesktop() {
			return window.innerWidth <= 648;
		},
	},
	methods: {
		unlockSetence(topic) {
			if (topic.formationId == null) return 'Vous n’avez pas accès à cette formation';
			return `Cette formation sera débloquée le ${new Date(topic.beginDate).getDate()} ${getMonthInFrench(
				topic.beginDate
			)}`;
		},
		close() {
			this.dialog = false;
			this.closeModalInParent();
		},
		setNavBarZIndex(zindexNumber) {
			const routeNames = ['Assistant', 'Rules'];
			if (routeNames.includes(this.$router.currentRoute.name)) setZindexByClassNames(['navbar'], zindexNumber);
		},
	},
	created() {
		if (this.topic.slug === 'orthographe') this.modalContent = DataForOrthographiqTopic;
		else if (this.topic.slug === 'redaction') this.modalContent = DataForRedactionTopic;
	},
	mounted() {
		this.setNavBarZIndex(0);
	},
	destroyed() {
		this.setNavBarZIndex(210);
	},
};
</script>

<style scoped lang="scss">
.topic-introduction-modal {
	.stroke {
		background-color: black;
		width: 2px;
		height: calc(100% - 50px);
		margin-right: 30px;
	}
	.close-action {
		position: absolute;
		right: 8px;
		top: 8px;
	}
	.card-title {
		h5 {
			font-size: 20px;
		}
		p {
			font-size: 1.3rem;
			font-weight: bold;
			margin-bottom: 30px;
		}
	}

	.card-body {
		display: flex;
		.left-col {
			margin-right: 1rem;
		}
		.right-col {
			padding-left: 20px;
		}
	}

	.image {
		width: 300px;
		height: 250px;
	}

	.separator {
		margin: auto 0;
		width: 4px;
		height: 230px;
		background-color: black;
	}

	@media screen and (max-width: 648px) {
		.close-action {
			right: -8px;
			top: -8px;
		}
		.card-body {
			padding: 0 16px;
			gap: 16px;
			flex-direction: column;
			align-items: center;
			position: relative;
			.left-col {
				margin-right: 0;
				width: 100%;

				.image {
					width: 100%;
				}
			}
			.right-col {
				border-left: none;
				padding-left: 0;
				padding: 0 10px;
			}
		}
		.card-title {
			padding: 16px;
			p {
				margin-bottom: 10px;
			}
		}
		.cta-btn {
			width: 100%;
			margin-bottom: 1rem;
		}
	}
}
</style>
