<template>
	<div>
		<img
			v-if="isForSessionFormation"
			class="ortho-logo-nav cursor-pointer"
			src="@/assets/logos/logo-long-propre.png"
			@click="directToRedaction"
		/>
		<a v-else href="/">
			<img class="ortho-logo-nav cursor-pointer" src="@/assets/logos/logo-long-propre.png" />
		</a>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'OrthographiqLogo',
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		isForSessionFormation() {
			if (this.formationProgress == null) return false;
			return this.formationProgress.formation.for_session;
		},
	},
	methods: {
		async directToRedaction() {
			await this.$store.dispatch('profile/formationProgress', { idFormation: this.formationProgress.formation._id });
			this.$router.push({
				name: 'Redaction Dashboard Index',
				params: { formationId: this.formationProgress.formation._id },
			});
		},
	},
};
</script>

<style lang="scss" scoped></style>
