import { ANDROID_REFERRER_KEYWORD } from '../config/context';
import { getAppContextFromLocalStorage } from './context';

/**
 * @param {string} className
 * @param {number} opacity
 */
export const setOpacity = (className, opacity) => {
	const ele = document.querySelector(`.${className}`);
	if (ele == null) return;
	ele.style.opacity = opacity;
};

/**
 * @param {string} className
 * @param {number} zindex
 */
const setZindex = (className, zindex) => {
	const ele = document.querySelector(`.${className}`);
	if (ele == null) return;
	ele.style.zIndex = zindex;
};

/**
 * @param {string | string[]} classNames
 * @param {number} zindex
 */
export const setZindexByClassNames = (classNames, zindex) => {
	if (typeof classNames === 'string') setZindex(classNames, zindex);
	else if (Array.isArray(classNames)) classNames.forEach((className) => setZindex(className, zindex));
};

/**
 * @param {string} className
 * @param {string} display
 * @param {string} priority
 * @returns
 */
export const setDisplay = (className, display, priority) => {
	const ele = document.querySelector(`.${className}`);
	if (ele == null) return;
	ele.style.setProperty('display', display, priority);
};

/**
 * When access by android twa app it will send a string of referrer Eg: android-app://com.orthographiq.staging.twa While browser will return an empty string
 * @returns is user using Android TWA APP
 */
export const detectAndroidUserByReferrer = () => {
	const localContext = getAppContextFromLocalStorage();
	const referrer = localContext.referrer;
	if (referrer.includes(ANDROID_REFERRER_KEYWORD)) return true;
	return false;
};

/**
 * Injected JS in iOS app set value to localStorage. This function get value to see if user is on iOS app
 * @returns is user using ios app
 */
export const detectIosApp = () => {
	const platform = localStorage.getItem('orthographiq-app-platform');
	if (platform === 'ios') return true;
	return false;
};

/**
 * Get static image url from assets/images folder
 * @param {string} imageName
 * @returns {string} image url
 */
export const getImgUrl = (imageName) => {
	const images = require.context('@/assets/images', false);
	return images('./' + imageName);
};

/**
 * Subtract number of date from now
 * @param {number} days
 * @return {number} is unix timestamp format
 */
export const substractFromNow = (days) => {
	const now = new Date();
	return now.setDate(now.getDate() - days);
};
